<template>
	<BaseLoading v-if="isLoading" />
	<FormSubHeaders
		v-else
		:sub-header-type="subHeaderType"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import FormSubHeaders from '@/components/FormSubHeaders.vue';

import { mapActions } from 'vuex';
import { scrollToTop } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';
import { SUB_HEADER_TYPES } from '../enums/subHeader';

export default {
	name: 'SubHeaderCreate',

	components: {
		FormSubHeaders,
	},

	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		subHeaderType: {
			type: String,
			default: SUB_HEADER_TYPES.HOMEPAGE,
		},
		routerToSuccess: {
			type: String,
			default: ROUTE_NAME.SUB_HEADER_CART,
		},
	},

	methods: {
		...mapActions({
			createSubHeader: 'subHeaders/createSubHeader',
		}),

		async handleSubmit(params = {}) {
			try {
				const body = {
					...params,
					type: this.subHeaderType,
				};
				await this.createSubHeader({
					params: body,
				});
				this.$router.push({ name: this.routerToSuccess });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
