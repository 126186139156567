<template>
	<div>
		<CRow>
			<CCol xl="12" class="d-flex align-items-center">
				<label class="toggle-label" for="display-status">Store-front visibility</label>
				<CSwitch
					id="display-status"
					:checked="isContentVisible"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
					@update:checked="handleUpdateStoreFrontVisibility"
				/>
				<span>{{ storeFrontVisibilityText }}</span>
			</CCol>
		</CRow>
		<CRow class="mt-4">
			<CCol md="12">
				<label>Content text (TH)*</label>
				<BaseRichTextEditor
					v-model="$v.contentThValue.$model"
					:is-valid="!$v.contentThValue.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. Compare iPhone >>"
				/>
			</CCol>
			<CCol md="12">
				<label>Content text (EN)*</label>
				<BaseRichTextEditor
					v-model="$v.contentEnValue.$model"
					:is-valid="!$v.contentEnValue.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. 5% Compare iPhone >>"
				/>
			</CCol>
			<CCol v-if="hasContentLink" md="12">
				<CInput
					v-model.trim="$v.linkValue.$model"
					:is-valid="!$v.linkValue.$error && null"
					:invalid-feedback="$t('global.error.url')"
					:description="'Allow only url. example: https://www.studio7thailand.com'"
					label="Content link"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import { STATUS as mappingStatus } from '../mapping/banner';

export default {
	name: 'SubHeaderGeneral',

	validations() {
		return {
			contentThValue: {
				required,
			},
			contentEnValue: {
				required,
			},
			linkValue: {
				url,
			},
		};
	},

	props: {
		contentTh: {
			type: String,
			default: '',
		},
		contentEn: {
			type: String,
			default: '',
		},
		link: {
			type: String,
			default: '',
		},
		isContentVisible: {
			type: Boolean,
			default: false,
		},
		hasContentLink: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		contentThValue: {
			get() {
				return this.contentTh;
			},
			set(value) {
				this.$emit('update:contentTh', value);
			},
		},

		contentEnValue: {
			get() {
				return this.contentEn;
			},
			set(value) {
				this.$emit('update:contentEn', value);
			},
		},

		linkValue: {
			get() {
				return this.link;
			},
			set(value) {
				this.$emit('update:link', value);
			},
		},

		storeFrontVisibilityText() {
			const text = mappingStatus[this.isContentVisible] || '';
			return this.$t(text);
		},
	},

	methods: {
		handleUpdateStoreFrontVisibility(value) {
			this.$emit('update:isContentVisible', value);
		},
	},
};
</script>

<style lang="scss" scoped>
	.toggle {
		&-label {
			width: rem(162);
			margin: 0 rem(24) 0 0;
		}

		&-status {
			margin-right: rem(12);
		}
	}
</style>
