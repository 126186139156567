<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="formData.id"
			ref="generalTop"
			name-label="SubHeader Name"
			id-label="SubHeader"
			:show-widget="false"
			:show-status="false"
			:name.sync="formData.name"
			:priority.sync="formData.priority"
		/>

		<BannerDate
			ref="date"
			:status.sync="formData.status"
			:start-date.sync="formData.startDate"
			:start-time.sync="formData.startTime"
			:end-date.sync="formData.endDate"
			:end-time.sync="formData.endTime"
		/>

		<BannerContentWrapper
			:has-banner-link="false"
			desktop-img="/assets/images/sub-headers-ribbon-desktop.svg"
			mobile-img="/assets/images/sub-headers-ribbon-mobile.svg"
		>
			<template #title>
				Sub header ribbon
			</template>
			<template #detail>
				Sub header ribbon homepage will be displayed in sub headers ribbon.
			</template>

			<CRow class="mt-4">
				<CCol>
					<div>
						<Label>Select first background color*</Label>
						<BaseColorPicker v-model="formData.backgroundColorInitial" />
					</div>
				</CCol>
				<CCol>
					<div>
						<Label>Select secondary background color*</Label>
						<BaseColorPicker v-model="formData.backgroundColorFinal" />
					</div>
				</CCol>
			</CRow>

			<SubHeaderGeneral
				ref="general"
				:content-th.sync="formData.contentTh"
				:content-en.sync="formData.contentEn"
				:is-content-visible.sync="formData.isContentVisible"
				:has-content-link="false"
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:remove-text="isEditMode ? $t('global.general.delete.remove', { type: 'SubHeader' }) : null"
			:disabled-confirm="isLoading || isUpdating"
			button-confirm-text="Confirm"
			:is-edit="isEditMode"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: routerToCancel})"
			@onRemove="isEditMode && routerToRemove ? $refs['modal-remove'].open() : null"
		/>

		<!-- Modal -->
		<BaseModalConfirmDelete
			v-if="routerToRemove"
			ref="modal-remove"
			:handle-remove="handleRemove"
			:title="$t('global.general.delete.title', { type: 'SubHeader' })"
			:description="$t('global.general.delete.description', { type: 'SubHeader' })"
			@onSuccess="$router.push({ name: routerToRemove})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import SubHeaderGeneral from '@/components/SubHeaderGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BannerGeneral from '@/components/BannerGeneral.vue';

import { SUB_HEADER_TYPES, SUB_HEADER_DEFAULT_DATA } from '../enums/subHeader';

import { transformedSubHeaderToAPI } from '../assets/js/transform/subHeaders';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'FormSubHeaders',

	components: {
		BannerDate,
		BannerGeneral,
		SubHeaderGeneral,
		BannerContentWrapper,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		defaultData: {
			type: Object,
			default: () => SUB_HEADER_DEFAULT_DATA,
		},
		data: {
			type: Object,
			default: () => {},
		},
		subHeaderType: {
			type: String,
			default: null,
		},
		routerToCancel: {
			type: String,
			default: null,
		},
		routerToRemove: {
			type: String,
			default: null,
		},
	},

	data() {
		const {
			id = null,

			// Top General
			name = '',
			priority = 0,

			// Date
			status = true,
			startDate = null,
			startTime = '00:00',
			endDate = null,
			endTime = '23:59',

			// content
			contentTh = null,
			contentEn = null,
			link = null,
			isContentVisible = false,
			backgroundColorFinal = '#FFFFFF',
			backgroundColorInitial = '#000000',
		} = this.defaultData || {};

		return {
			formData: {
				id,
				name,
				priority,
				contentTh,
				contentEn,
				link,
				status,
				startDate,
				startTime,
				endDate,
				endTime,
				isContentVisible,
				backgroundColorFinal,
				backgroundColorInitial,
			},
		};
	},

	computed: {
		isSubHeaderCart() {
			return this.subHeaderType === SUB_HEADER_TYPES.CART;
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (value) {
					this.formData.id = value.id;
					this.formData.name = value.name;
					this.formData.priority = value.priority;

					this.formData.startDate = value.startDate;
					this.formData.startTime = value.startTime;
					this.formData.endDate = value.endDate;
					this.formData.endTime = value.endTime;

					this.formData.contentTh = value.contentTh;
					this.formData.contentEn = value.contentEn;
					this.formData.link = value.link;
					this.formData.isContentVisible = value.isContentVisible;
					this.formData.backgroundColorFinal = value.backgroundColorFinal;
					this.formData.backgroundColorInitial = value.backgroundColorInitial;
					this.formData.desktopBannerContentTh = value.desktopBannerContentTh;
					this.formData.desktopBannerContentEn = value.desktopBannerContentEn;
					this.formData.mobileBannerContentTh = value.mobileBannerContentTh;
					this.formData.mobileBannerContentEn = value.mobileBannerContentEn;
					this.formData.isBannerVisible = value.isBannerContentVisible;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteSubHeader: 'subHeaders/deleteSubHeader',
		}),

		async handleSubmit() {
			const refs = this.$refs;
			const generalTopRef = refs.generalTop.$v;
			const generalRef = refs.general.$v;
			const dateRef = refs.date.$v;

			generalTopRef.$touch();
			generalRef.$touch();
			dateRef.$touch();

			if (generalTopRef.$invalid || generalRef.$invalid || dateRef.$invalid) {
				return;
			}
			this.formData = {
				...this.formData,
				startAt: convertDateTimeToUTC(this.formData.startDate, this.formData.startTime),
				endAt: convertDateTimeToUTC(this.formData.endDate, this.formData.endTime),
			};

			this.$emit('onSubmit', transformedSubHeaderToAPI(this.formData));
		},
		async handleRemove() {
			await this.deleteSubHeader(this.formData.id);
		},
	},
};
</script>
